import React from "react";
import Layout from "../components/layout";
import { Link, Trans, useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

const AboutPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Layout>
        <h1>Legal mentions</h1>
        <h2>Presentation of the site</h2>
        <h3>Site editor</h3>
        <p>
          Stéphane Catoire
          <br />
          41 bd Alfred Wallach
          <br />
          68100 Mulhouse
          <br />
          <a href="mailto:contact@stephanecatoire.com">
            contact@stephanecatoire.com
          </a>
          <br />
        </p>
      </Layout>
    </>
  );
};

export default AboutPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;